<template>
  <b-modal
    :visible="visible"
    hide-footer
    hide-header
    centered
    @change="$emit('change', false)"
  >
    <div class="my-4">
      <span
        class="b-avatar shadow mb-2 badge-primary rounded-circle bg-light-primary d-block mx-auto"
        style="width: 50px; height: 50px;"
      >
        <b-icon v-if="changingPlan" icon="arrow-left-right"  font-scale="2" class="mt-75"></b-icon>
        <b-icon v-else icon="credit-card"  font-scale="2" class="mt-75"></b-icon>
      </span>
      <h4 class="text-center font-weight-normal">
        {{ changingPlan ? $t('backoffice.subscriptions.manage.success.change-plan') : $t('backoffice.subscriptions.manage.success.upgrade') }}
      </h4>
      <h5 class="text-center text-primary">{{ $t('backoffice.subscriptions.manage.success.time')}}</h5>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'LoadingModal',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    changingPlan: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
