<template>
  <backoffice-container>
    <b-card v-if="isLoading">
      <b-spinner variant="primary" type="grow" small class="my-5 d-block mx-auto" />
    </b-card>
    <b-card v-else class="px-1 pb-2">
      <div class="d-flex justify-content-between flex-wrap my-1 mb-2">
        <h4>{{ $t('backoffice.subscriptions.manage.title') }}</h4>
      </div>
      <!-- Content -->
      <div v-if="items && !isLoading" class="d-flex flex-column align-items-center">
        <div class="text-center mb-2">
          <h2>{{ $t('backoffice.subscriptions.manage.subtitle') }}</h2>
          <h4>{{ $t('backoffice.subscriptions.manage.text') }} </h4>
        </div>
        <b-row :class="isSmall ? 'flex-wrap' : isMedium ? 'flex-nowrap': ''">
          <!-- ** ORDER ** -->
          <b-col 
            v-for="item in items" 
            :key="item.key" 
            class="d-flex align-items-stretch justify-content-center" 
            :class="isMedium ? 'px-25' : ''"
          >
            <b-card 
              no-body 
              :class="isMedium ? 'card-standard card-standard--plans--small' : 'card-standard card-standard--plans'" 
              style="cursor:auto;"
            >
              <b-card-body class="px-0" :class="isMedium ? 'py-0' : 'py-25'">
                <!-- Element Name -->
                <!-- <div class="text-right mt-n1">
                  <b-badge v-if="isPopular(item) && currentSubscriprion != 'BUSINESS' && currentSubscriprion != 'ENTERPRISE'" variant="primary" class="mr-n2">Popular</b-badge>
                </div> -->
                <h2 class="mt-2 text-center title" :class="item.headline?'':'mb-2'">
                  {{ translate(item.name) }}
                </h2>
                <p v-if="item.headline" class="mb-1 text-center">
                  {{ translate(item.headline) }}
                </p>
                <div class="text-primary d-flex align-items-center justify-content-center price-container">
                  <text-resizable
                    :text-style="'text-primary text-center h5'"
                    :text="item.total != null && !isEnterprise(item) ? parseFloat(item.total).toLocaleString(currentLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3
                    }) + item.currencySymbol : translateTranslationTable(currentLocale, enterprisePrice)"
                    :default-font-size="3"
                    :default-text-length="7"
                    :font-size-to-rest="0.30"
                  />

                  <div div class="d-flex ml-50 flex-column">
                    <small v-if="item.interval === 'month'">
                      / <span v-if="item.intervalCount&&item.intervalCount>1">{{ item.intervalCount }} {{ $t('onboarding.subscriptions.months') }}</span><span v-else>{{ $t('onboarding.subscriptions.month') }}</span>
                    </small>
                    <small v-if="item.interval === 'year'">
                      / {{ $t('onboarding.subscriptions.year') }}
                    </small>
                  </div>
                </div>
                <div v-if="item.description" class="min-height-5">
                  <p class="text-muted small mb-25 mt-2">
                    {{ $t('onboarding.subscriptions.includes') }}:
                  </p>
                  <div v-for="(benefit, index) in translate(item.description).split('\n')" :key="index" class="d-flex">
                    <b-icon-check2 variant="primary" font-scale="0.8" class="mr-50 check mt-25" />
                    <div class=" font-small-2 m-0">
                      {{ benefit }}
                    </div>
                  </div>
                </div>
                <div v-else class="min-height-5">
                  <p />
                </div>
                <div
                  class="w-100 mt-2 d-flex
                  justify-content-between"
                >
                  <b-button
                    class="mx-2 mt-1 w-100 font-weight-bolder"
                    :variant="isActive(item) ? 'success' : getButtonText(item) === $t('backoffice.subscriptions.manage.downgrade') ? 'outline-primary' : 'primary'"
                    :disabled="isActive(item)"
                    @click="translateTranslationTable(currentLocale, item.name).includes('ENTERPRISE') ? handleContact() : handleEditPlan(item.priceKey)"
                  >
                    {{ translateTranslationTable(currentLocale, item.name).includes('ENTERPRISE') && !currentSubscriprion.includes("ENTERPRISE") ? $t('products.contact.action') : getButtonText(item) }}
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <payment-modal 
      v-model="isLaodingPayment" 
      v-ripple.400="'rgba(234, 84, 85, 0.15)'" 
    />
    <payment-modal 
      v-model="isChangingPlan" 
      :v-ripple.400="'rgba(234, 84, 85, 0.15)'" 
      changing-plan 
    />
    <!-- Contact modal -->
    <b-modal
      v-model="contactModalOpened"
      hide-footer
      hide-header
      centered
    >
      <div class="my-4">
        <span
          class="b-avatar shadow mb-2 badge-primary rounded-circle bg-light-primary d-block mx-auto"
          style="width: 50px; height: 50px;"
        >
          <b-icon icon="envelope"  font-scale="2" class="mt-75"></b-icon>
        </span>
        <h4 class="text-center font-weight-normal">
          {{$t('subcommunities.contact.contact-message')}}
        </h4>
      </div>
    </b-modal>
    </backoffice-container>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import TextResizable from '@/backoffice/components/TextResizable.vue';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import PaymentModal from '@core/components/modal/PaymentModal.vue';

export default {
  name: 'ManageSubscriptions',
  components: {
    TextResizable,
    BackofficeContainer,
    PaymentModal,
  },
  data() {
    return {
      isLoading: true,
      intervalMonth: true,
      isLaodingPayment: false,
      isChangingPlan: false,
      contactModalOpened: false,
      isMedium: false,
      isSmall: false,
    };
  },
  computed: {
    items() {
      if (this.$store.getters.subscriptionPlans != null) {
        return this.$store.getters.subscriptionPlans.unpaginated;
      }
      return [];
    },
    hasSubscription() {
      return !!this.$store.getters.communities?.toPay?.unpaginated?.[0]?.hasSubscription || false;
    },
    Placeholder() {
      return Placeholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    currentSubscriprion() {
      // console.log("Ejemplo para ir bloqueando vistas:");
      // console.log("en esta comunidad se pueden añadir un total de ", LEVEL_CONFIG.Available_Languages[this.$store.getters.currentCollective.level], " idiomas disponibles");
      // console.log("gracias al plan ", this.$store.getters.currentCollective.subscription);
      return translateTranslationTable(this.currentLocale, this.$store.getters.currentCollective.subscription).toUpperCase() || translateTranslationTable(this.currentLocale, this.freePlan);
    },
    enterprisePrice() {
      return {
        ca: 'Parlem',
        en: 'Let\'s talk',
        es: 'Hablamos',
      };
    },
    freePlan() {
      return {
        es: "GRATUITO",
        en: "FREE",
        ca: "GRATUIT",
      };
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchData();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMedium = window.innerWidth < 1600;
      this.isSmall = window.innerWidth < 1390;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field) || '';
    },
    goToOrderPurchase(item) {
      return this.$router.replace({
        name: 'my-checkout',
        params: { community: this.communitySlug, id: item.key },
      });
    },
    goToError() {
      return this.$router.replace({
        name: 'my-error',
      });
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch('getItemsPayments', {
        itemType: 'subscriptionPlans',
        page: 1,
        requestConfig: {
          orderBy: -1,
          isForNectios: true,
        },
      });
      this.isLoading = false;
    },
    isActive(item) {
      // console.log('this.currentSubscriprion', this.currentSubscriprion);
      // if(this.currentSubscriprion === "COPERNIC COMMUNITY"){
      // TODO...
      // }
      return translateTranslationTable(this.currentLocale, item.name) === this.currentSubscriprion
    },
    isPopular(item) {
      return translateTranslationTable(this.currentLocale, item.name).includes("BUSINESS")
    },
    isEnterprise(item) {
      return translateTranslationTable(this.currentLocale, item.name).includes("ENTERPRISE")
    },
    getButtonText(item) {
      if (this.isActive(item)) {
        return this.$t('backoffice.subscriptions.manage.current')
      }
      if (this.currentSubscriprion.includes("GRATUIT") || this.currentSubscriprion.includes("FREE") || this.currentSubscriprion.includes("GRATUITO")) {
        return this.$t('backoffice.subscriptions.manage.upgrade')
      }
      if (this.currentSubscriprion.includes("SIC")) {
        if (translateTranslationTable(this.currentLocale, item.name).includes("BUSINESS")) {
          return this.$t('backoffice.subscriptions.manage.upgrade')
        }
        return this.$t('backoffice.subscriptions.manage.downgrade')
      }
      return this.$t('backoffice.subscriptions.manage.downgrade')

    },
    async handleEditPlan(priceKey) {
      this.isSending = true;
      try {
        const response = await this.$store.dispatch('createItemPayments', {
          itemType: 'editCommunitySubscription',
          item: {
            priceKey,
          },
          noSet: true,
        });
        if (response?.data?.ok) {
          this.isChangingPlan = true;
          setTimeout(async () => {
            window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
              '{subdomain}',
              'app',
            ).replace('{slug}', `${this.$store.getters.currentCollective.slug}/backoffice/subscriptions`);
            this.isChangingPlan = false;
          }, 2000);
        } else {
          this.isLaodingPayment = true;
          setTimeout(() => {
            window.open(response.data);
            this.isLaodingPayment = false;
          }, 2000);
        }
        this.isSending = false;
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
        this.isSending = false;
      }
    },
    translateTranslationTable,
    async handleContact() {
      try {
        await this.$store.dispatch('postItem', {
          type: 'backoffice/mailToSales',
          requestConfig: {
            memberKey: this.$store.getters.loggedMember.key,
          },
        });
        this.contactModalOpened = true;
        setTimeout(() => {
          this.contactModalOpened = false;
          }, 3000);
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

  .price-container {
    min-height: 65.8px;
  }
  .active {
    border: 2px solid $primary;
  }
  </style>
